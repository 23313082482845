// src/components/Testimonials.js
import React, { useRef, useEffect } from 'react';

const testimonialElements = {
    logo: "/media/g_reviews_logo.svg",
    ratings: "media/start_rate.svg",
};

const testimonials = [
    {
        name: "Sarah K.",
        city: "Fort Lauderdale, FL",
        review: "I had a fantastic experience with Florida Upgrade. Eric was extremely knowledgeable and guided us through the entire process seamlessly. The new roof looks amazing, and the team was professional and efficient. Highly recommend!",
    },
    {
        name: "Michael T.",
        city: "Hollywood, FL",
        review: "Joe from Florida Upgrade was a pleasure to work with. He answered all our questions and made sure we were comfortable with our decision. The installation team did an outstanding job, and we couldn't be happier with our new roof!",
    },
    {
        name: "Lisa W.",
        city: "Pompano Beach, FL",
        review: "A huge thank you to Sean and the Florida Upgrade team! They were prompt, courteous, and did an excellent job on our roof. The whole process was stress-free, and the quality of work is top-notch.",
    },
    {
        name: "David R.",
        city: "Deerfield Beach, FL",
        review: "Jared at Florida Upgrade was fantastic! He explained everything in detail and made sure we understood each step. The crew was efficient and left our property spotless after the job. Highly satisfied with our new roof!",
    },
    {
        name: "Emily H.",
        city: "Dania Beach, FL",
        review: "Jojo was amazing to work with! He was very patient and took the time to address all our concerns. The Florida Upgrade team did a stellar job on our roof, and the results are beyond our expectations.",
    },
    {
        name: "Chris M.",
        city: "Tamarac, FL",
        review: "Steph and the Florida Upgrade team were exceptional. From the initial consultation to the final installation, everything was handled professionally. Our new roof looks great, and we couldn't be happier with the service provided.",
    },
    {
        name: "Jessica L.",
        city: "Miami, FL",
        review: "Eric provided excellent customer service throughout our roofing project. The Florida Upgrade team was prompt, professional, and did an outstanding job. We highly recommend them for any roofing needs!",
    },
    {
        name: "Robert P.",
        city: "Lighthouse Point, FL",
        review: "Working with Joe at Florida Upgrade was a great experience. He was knowledgeable and very helpful. The installation team was efficient and did a superb job. We are thrilled with our new roof and the overall service.",
    },
    {
        name: "Megan B.",
        city: "Sunny Isles Beach, FL",
        review: "Sean and the Florida Upgrade crew were fantastic from start to finish. They were professional, courteous, and completed the job on time. The quality of the new roof is excellent, and we are extremely satisfied.",
    },
    {
        name: "Tom S.",
        city: "Aventura, FL",
        review: "Jared at Florida Upgrade made the entire process easy and stress-free. The team did an incredible job on our roof, and their attention to detail was impressive. We highly recommend Florida Upgrade for their outstanding service.",
    },
];




const Testimonials = () => {
    const swiperElRef = useRef(null);

    useEffect(() => {
        const params = {
            slidesPerView: 1,
            pagination: true,
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                576: {
                    slidesPerView: 2,
                    spaceBetween: 5,
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 5,
                },
                1024: {
                    slidesPerView: 4,
                    spaceBetween: 5,
                },
            },
        };

        const initializeSwiper = () => {
            if (swiperElRef.current) {
                Object.assign(swiperElRef.current, params);
                swiperElRef.current.initialize();
            }
        };

        initializeSwiper();
        window.addEventListener('resize', initializeSwiper);

        return () => {
            window.removeEventListener('resize', initializeSwiper);
        };
    }, []);

    return (
        <section className="bg-[#F0F0F0] px-4 py-5">
            <h3 className="text-center text-2xl md:text-3xl font-bold uppercase text-[#2] py-2">Celebrating Our Clients
                Experiences!</h3>
            <swiper-container className="my-20" init="false" ref={swiperElRef}>
                {testimonials.map((testimonial, index) => (
                    <swiper-slide className="rounded-2xl bg-white p-4 shadow-lg mb-3" key={index}>
                        <div className="swiper-slide rounded-2xl bg-white p-4 shadow-lg">
                            <div className="flex items-center justify-between gap-2">
                                <div>
                                    <div className="flex text-sm font-semibold text-sage_black">{testimonial.name}</div>
                                    <div className="font-normal text-xs text-sage_black">{testimonial.city}</div>
                                </div>

                                <img src={testimonialElements.ratings} alt="Star" loading="lazy" />
                            </div>
                            <div className="text-sm leading-snug text-gray-700 p-4 md:text-base">
                                {testimonial.review}
                            </div>
                            <div className="flex justify-end items-center">
                                <img src={testimonialElements.logo} alt="Google Reviews" className="h-6 w-auto" />
                            </div>
                        </div>
                    </swiper-slide>
                ))}
            </swiper-container>
        </section>
    );
};

export default Testimonials;
