// src/components/SwiperGallery.js
import React, { useRef, useEffect } from 'react';

const SwiperGallery = () => {
    const swiperRef = useRef(null);

    const slides = [
        { src: "/media/roof.webp", alt: "Roof" },
        { src: "/media/roof_1.webp", alt: "Roof 1" },
        { src: "/media/roof_2.webp", alt: "Roof 2" },
        { src: "/media/roof_3.webp", alt: "Roof 3" },
        { src: "/media/roof_4.webp", alt: "Roof 4" },
        { src: "/media/roof_5.webp", alt: "Roof 5" },
        { src: "/media/roof_6.webp", alt: "Roof 6" },
        { src: "/media/roof_7.webp", alt: "Roof 7" },
        { src: "/media/roof_8.webp", alt: "Roof 8" }
    ];

    useEffect(() => {
        const params = {
            slidesPerView: 3,
            spaceBetween: 5,
            navigation: true,
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 5,
                },
                768: {
                    slidesPerView: 5,
                    spaceBetween: 10,
                },
            },
        };

        if (swiperRef.current) {
            Object.assign(swiperRef.current, params);
            swiperRef.current.initialize();
        }
    }, []);

    return (
        <section className="min-w-full py-2">
            <h3 className="text-center text-2xl font-bold uppercase text-[#2] py-2">Our Craft in Action!</h3>
            <swiper-container init="false" ref={swiperRef}>
                {slides.map((slide, index) => (
                    <swiper-slide key={index}>
                        <img src={slide.src} className="w-full object-cover" alt={slide.alt} loading="lazy" />
                    </swiper-slide>
                ))}
            </swiper-container>
        </section>
    );
};

export default SwiperGallery;