// src/components/LeadForm.js
import React from 'react';
import RoofingZipCodeMultiStepForm from './RoofingZipCodeMultiStepForm';

const LeadForm = () => {


    return (
        <div className="bg-cello text-white p-3 md:p-3 rounded-lg mb-4" id="form">
            < RoofingZipCodeMultiStepForm />
            <div className="text-center text-xl font-bold mb-2">
                <span className="text-amber-400">12</span> appointments already booked today
            </div>
            <div className="text-center text-neutral-400 text-xs mt-2">🔒 Your information is secured and will not be sold to anyone</div>

        </div>

    );
};

export default LeadForm;