import React from "react";

const currentMonthName = new Date().toLocaleString("default", { month: "long" });

const MonthlyOffer = () => {
    return (

        <div
            className='flex flex-col-reverse md:flex-row border-dashed border-4 border-gray-500 md:p-4 p-2 rounded-lg shadow-lg mb-2 md:items-top'>
            <img src="media/roofer.webp" alt="Roofer"
                className="md:h-60 h-40 md:mr-4 mb-4 md:mb-0 object-cover rounded" />
            <div className="text-center">
                <div className="text-sky-700 md:text-3xl text-2xl font-bold mb-2">
                    Only This {currentMonthName}:
                </div>
                <div className="text-zinc-800 md:text-xl text-xl font-bold">
                    Enjoy No Payments for An Entire Year with Low Monthly Payments Starting at Just $99/Month
                    <div> + <br className="mb-4" /><span
                        className="bg-yellow-300 text-red-700 px-2  my-2 rounded font-bold">Save $500</span><br /><span
                            className="md:text-xl text-xl">on Installation or Repair!</span></div>
                </div>
            </div>
        </div>
    );
};

export default MonthlyOffer;