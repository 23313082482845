// src/components/CountdownTimer.js
import React, { useEffect } from 'react';

const CountdownTimer = () => {
    useEffect(() => {
        const updateCountdown = () => {
            const now = new Date();
            const year = now.getFullYear();
            const month = now.getMonth() + 1;
            const endOfMonth = new Date(year, month, 0, 23, 59, 59);
            const timeLeft = endOfMonth - now;

            const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
            const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

            document.querySelector('#days').style.setProperty('--value', days);
            document.querySelector('#hours').style.setProperty('--value', hours);
            document.querySelector('#minutes').style.setProperty('--value', minutes);
            document.querySelector('#seconds').style.setProperty('--value', seconds);
        };

        const interval = setInterval(updateCountdown, 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="bg-cello text-white max-w-md mx-auto text-xl text-center font-bold p-4 rounded-lg mb-4">
            <div>Don't Miss Out! This Offer Expires In:</div>
            <div className="flex justify-center items-center space-x-2">
                <div className="grid grid-flow-col gap-5 text-center auto-cols-max">
                    <div className="flex flex-col p-2 bg-neutral rounded-box text-neutral-content">
                        <span className="countdown font-mono text-3xl">
                            <span id="days" style={{ '--value': 0 }}></span>
                        </span>
                        days
                    </div>
                    <div className="flex flex-col p-2 bg-neutral rounded-box text-neutral-content">
                        <span className="countdown font-mono text-3xl">
                            <span id="hours" style={{ '--value': 0 }}></span>
                        </span>
                        hours
                    </div>
                    <div className="flex flex-col p-2 bg-neutral rounded-box text-neutral-content">
                        <span className="countdown font-mono text-3xl">
                            <span id="minutes" style={{ '--value': 0 }}></span>
                        </span>
                        min
                    </div>
                    <div className="flex flex-col p-2 bg-neutral rounded-box text-neutral-content">
                        <span className="countdown font-mono text-3xl">
                            <span id="seconds" style={{ '--value': 0 }}></span>
                        </span>
                        sec
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CountdownTimer;
