// src/components/Footer.js
import React from 'react';

const Footer = () => {
    return (
        <footer className="px-4 py-3 bg-cello flex items-baseline">
            <div className="mx-auto flex max-w-content-container flex-col items-center justify-center gap-x-5 gap-y-3 md:flex-row">
                <div className="flex flex-col items-center justify-start gap-1 pt-1">
                    <div className="text-center mb-4">
                        <div className="inline-block bg-sky-700 text-white text-xl font-extrabold py-2 px-4 rounded">Florida Upgrade®</div>
                    </div>
                    <p className="text-xs font-normal leading-tight text-white md:text-base">Licensed & Insured</p>
                    <nav className="flex items-start justify-start gap-3.5">
                        <a href="https://floridaupgrade.com/" className="text-center text-xs font-normal leading-tight text-white underline md:text-base">Home</a>
                        <a href="https://floridaupgrade.com/services/" className="text-center text-xs font-normal leading-tight text-white underline md:text-base">Services</a>
                        <a href="https://floridaupgrade.com/contact-us/" className="text-center text-xs font-normal leading-tight text-white underline md:text-base">Contact Us</a>
                        <a href="https://floridaupgrade.com/blog/" className="text-center text-xs font-normal leading-tight text-white underline md:text-base">Blog</a>
                    </nav>
                </div>
                <p className="max-w-[22rem] shrink grow basis-0 text-center font-['Montserrat'] text-xs font-normal leading-tight text-white md:max-w-56 md:text-base">Providing High-Quality, Dependable Remodeling and Renovation Services in South Florida for over 2 decades</p>
                <div className="flex flex-row-reverse items-start justify-center gap-5 md:flex-row">
                    <address className="text-xs font-normal leading-none text-white md:text-base" itemScope itemType="http://schema.org/PostalAddress">
                        <span itemProp="streetAddress">116 FL-5</span><br />
                        <span itemProp="addressLocality">Fort Lauderdale</span>
                        <span itemProp="addressRegion">Florida</span>
                        <span itemProp="postalCode">33394</span><br />
                        <a href="mailto:info@summipremiumservices.ca" itemProp="email" style={{ textDecoration: 'none', color: 'inherit' }}>info@floridaupgrade.com</a><br />
                    </address>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
