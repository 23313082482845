// src/components/LeadForm.js
import React from 'react';

const LeadFormHeader = () => {
    return (
        <div className="bg-blue-900 rounded-md text-center mb-4 p-4">
            <div className="md:text-2xl text-xl font-bold text-white">Limited Program for the First 150 Homeowners!</div>
            <div className="md:text-xl text-lg text-yellow-300">Claim Your Free Roof Inspection Now</div>
        </div>
    );
};

export default LeadFormHeader;