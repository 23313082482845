// src/App.js
/* global ttq */
import React, { useEffect } from 'react';
import CountdownTimer from './components/CountdownTimer';
import LeadForm from './components/LeadForm';
import Testimonials from './components/Testimonials';
import SwiperGallery from './components/SwiperGallery';
import Footer from './components/Footer';
import { register } from 'swiper/element/bundle';
import 'swiper/element/css/navigation';
import 'swiper/element/css/pagination';
import 'swiper/swiper-bundle.css';
import './index.css';
import LeadFormHeader from './components/LeadFormHeader';
import MonthlyOffer from './components/MonthlyOffer';
import Header from './components/Header';

// Register Swiper web component globally
register();

function App() {
  useEffect(() => {
    ttq.track('ViewContent', {
      "contents": [
        {
          "content_id": "roofing_program_form_v060724",
          "content_name": "Roofing Form v060724",
          "content_category": "home_improvement",
          "brand": "Florida Upgrade"
        }
      ]
    });
  }, []);
  return (
    <div className="App">
      <main>
        <section className="min-w-full mx-auto py-4">
          <div className="md:max-w-3xl mx-auto md:p-4">
            <div className="bg-white p-3 md:p-6 rounded-lg shadow-lg">
              <section className="text-center ">
                <Header />

                <div className="flex flex-col gap-0 pb-2">
                  <h1 className="text-red-600 md:text-3xl text-2xl font-bold mb-4">
                    DON'T OVERPAY FOR YOUR ROOF!
                  </h1>

                  <h2 className="text-cello text-2xl font-semibold mb-2">
                    Find out if you are eligible for a <span className='font-bold text-red-600'>$0/Down</span>, Brand-New Roof with Florida's Upgrade Program</h2>
                  <h3 className="text-amber-400 text-2xl font-bold">

                  </h3></div>
              </section>
              <LeadForm />
              <LeadFormHeader />
              <MonthlyOffer />
              <CountdownTimer />
            </div>
          </div>
        </section>
        <section className="min-w-full mx-auto pt-2">
          <div className="bg-cello text-white text-center p-6 shadow-lg">
            <div className="text-4xl font-bold mb-2">#1 Roofing Company in South Florida</div>
            <div className="flex flex-col justify-center items-center mb-2">
              <div className="flex flex-wrap items-center justify-center gap-4">
                <img className="h-12 w-32" src="/media/g_rev copy.webp" alt="Google Reviews" loading="lazy" />
                <img className="h-12 w-16" src="/media/yelp_rev.png" alt="Yelp Reviews" loading="lazy" />
              </div>
            </div>
            <div className="text-xl font-medium">RATED 4.8⭐ based on over 2,200+ Reviews</div>
          </div>
        </section>
        <SwiperGallery />
        <Testimonials />
        <section className="bg-white">

          <div className="min-w-full mx-auto p-6 rounded-lg shadow-lg">
            <div className="text-center text-stone-900 text-3xl font-bold mb-4">Get A Free, No-Obligation Inspection Before it's Too Late!</div>
            <div className="flex justify-center py-2">
              <a href="#form">
                <button className="px-6 py-2.5 bg-amber-400 text-stone-900 text-xl font-semibold uppercase rounded shadow">
                  Request A FREE Inspection Today
                </button>
              </a>
            </div>

          </div>

        </section>
      </main>
      <Footer />
    </div>
  );
}

export default App;