// src/components/Header.js
import React from 'react';

const Header = () => {
    return (
        <header className="text-center mb-4">
            <div className="inline-block bg-sky-700 text-white text-2xl md:text-4xl font-extrabold py-2 px-4 rounded">
                Florida Upgrade<sup className="text-xl font-bold text-white">®</sup>
            </div>
        </header>
    );
};

export default Header;
