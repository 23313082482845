/* global ttq */

import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FaHome, FaPhone, FaEnvelope, FaClock, FaHandPointer } from 'react-icons/fa';
import sha256 from 'crypto-js/sha256';

// TikTok Pixel Identify Function
const tiktokIdentify = (data) => {
    ttq.identify({
        "email": sha256(data.email).toString(),
        "phone_number": sha256(data.phone).toString(),
        "external_id": sha256(data.zip).toString()
    });
};

// TikTok Track ClickButton
const trackClickButton = () => {
    ttq.track('ClickButton', {
        "contents": [
            {
                "content_id": "eligibility_check",
                "content_name": "Check Eligibility",
                "brand": "Florida Upgrade"
            }
        ]
    });
};

// TikTok Track SubmitForm
const trackSubmitForm = () => {
    ttq.track('SubmitForm', {
        "contents": [
            {
                "content_id": "roofing_form_submission",
                "content_name": "Roofing Program Form",
                "brand": "Florida Upgrade"
            }
        ]
    });
};

const schema = yup.object().shape({
    zip: yup.string().matches(/^\d{5}(?:[-\s]\d{4})?$/, "ZIP code is not valid").required("Zip is required"),
    homeowner: yup.string().required("Homeownership status is required"),
    roofAge: yup.string().required("Roof age is required"),
    roofType: yup.string().required("Roof type is required"),
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    address: yup.string().required("Address is required"),
    city: yup.string().required("City is required"),
    phone: yup.string().matches(/^(\+1|1)?\s*\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/, "Phone number is not valid").required("Phone number is required"),
    email: yup.string().email("Invalid email address").required("Email is required"),
});

const RoofingZipCodeMultiStepForm = ({ onStart }) => {
    const [step, setStep] = useState(0);
    const [formData, setFormData] = useState({});
    const { handleSubmit, control, trigger, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            zip: '',
            homeowner: '',
            roofAge: '',
            roofType: '',
            firstName: '',
            lastName: '',
            address: '',
            city: '',
            phone: '',
            email: ''
        }
    });

    const sendDataToAPI = async (data) => {
        try {
            const token = btoa(new Date().toISOString());
            const homeownerValue = data.homeowner === 'yes' ? 'Homeowner' : data.homeowner;
            const roofAgeValue = data.roofAge === 'yes' ? '+5>yrs' : data.roofAge;

            const standardizedData = {
                date: new Date().toISOString(),
                first_name: data.firstName,
                last_name: data.lastName,
                address: `${data.address}, ${data.city}`,
                zip: data.zip,
                state: 'FL',
                email: data.email,
                phone: data.phone,
                job_type: data.roofType,
                job_type_2: homeownerValue,
                job_type_3: roofAgeValue,
                affid: '88546',
                subid: 'v062024',
                tier: 'premium',
                cpl: 0,
                token: token
            };

            const response = await fetch('https://v7q6cz0hek.execute-api.us-east-1.amazonaws.com/v1/SubmitFLUpgradeForm', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(standardizedData)
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const result = await response.json();
            console.log(result);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    };

    const fireGoogleAdsConversion = () => {
        window.gtag('event', 'conversion', {
            'send_to': 'AW-11106253772/xGApCLzcvbgZEMz3768p',
            'value': 5.0,
            'currency': 'USD'
        });
    };

    const onSubmit = async (data) => {
        setFormData(data);
        tiktokIdentify(data); // TikTok Identify
        await sendDataToAPI(data);
        fireGoogleAdsConversion();
        trackSubmitForm(); // Track form submission
        setStep(6);
    };

    const onNext = async () => {
        let isValid = true;
        const fieldsToValidate = {
            0: ['zip'],
            4: ['address', 'city'],
            5: ['firstName', 'lastName', 'phone', 'email'],
        }[step];
        if (step === 0) {
            isValid = await trigger(fieldsToValidate);
            if (isValid) {
                const currentData = formData;
                currentData.zip = document.querySelector('[name="zip"]').value;
                setFormData(currentData);
                trackClickButton(); // Track button click
                setStep((prevStep) => prevStep + 1);
            }
        } else if (step >= 4 && step <= 5) {
            isValid = await trigger(fieldsToValidate);
            if (isValid) {
                setStep((prevStep) => prevStep + 1);
            }
        } else {
            setStep((prevStep) => prevStep + 1);
        }
    };

    const renderStep = () => {
        switch (step) {
            case 0:
                return <ZipStep control={control} errors={errors} onNext={onNext} />;
            case 1:
                return <HomeownerStep zip={formData.zip} control={control} onNext={onNext} />;
            case 2:
                return <RoofAgeStep control={control} onNext={onNext} />;
            case 3:
                return <RoofTypeStep control={control} onNext={onNext} />;
            case 4:
                return <Step4 control={control} errors={errors} onNext={onNext} />;
            case 5:
                return <ContactInfoStep control={control} errors={errors} />;
            case 6:
                return <ThankYouStep formData={formData} />;
            default:
                return <ZipStep control={control} errors={errors} onNext={onNext} />;
        }
    };

    return (
        <div className="max-w-md mx-auto p-4">
            <form>
                {renderStep()}
                <div className="flex justify-between mt-4">
                    {step >= 4 && step < 5 && <button type="button" onClick={onNext} className="mx-auto px-4 py-2 bg-amber-400 text-stone-900 text-lg rounded-lg shadow">Next</button>}
                    {step === 5 && <button type="button" onClick={handleSubmit(onSubmit)} className="mx-auto px-4 py-2 bg-amber-400 text-stone-900 text-lg rounded-lg shadow mx-auto">Submit</button>}
                </div>
            </form>
        </div>
    );
};

const ZipStep = ({ control, errors, onNext }) => {
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            onNext();
        }
    };

    return (
        <div className="space-y-4 text-center">
            <h2 className="text-xl font-bold text-amber-400">Is Your Home Eligible for Our Exclusive Roofing Program?</h2>
            <p className="text-lg text-white">Enter your zip code below to instantly find out if your home qualifies!</p>
            <Controller
                name="zip"
                control={control}
                render={({ field }) => (
                    <div className="flex flex-col items-center">
                        <input
                            {...field}
                            placeholder="Enter Your Zip Code"
                            autoComplete="postal-code"
                            className={`input-lg text-gray-700 dark:text-yellow-400 w-3/4 md:w-1/2 ${errors.zip ? 'border-red-500' : ''}`}
                            onKeyDown={handleKeyDown}
                        />
                        {errors.zip && <p className="text-red-500 mt-2">{errors.zip.message}</p>}
                    </div>
                )}
            />
            <div className="flex justify-center">
                <button
                    type="button"
                    className="px-8 py-3 bg-amber-400 text-stone-900 text-xl md:text-2xl font-semibold rounded-lg shadow-lg shadow-slate-700 transform transition-transform duration-300 hover:scale-105 hover:bg-amber-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-400 animate-custom-bounce flex items-center justify-center"
                    onClick={onNext}
                    id='check-eligibility'
                >
                    Check Eligibility! <FaHandPointer className="text-white ml-2" />
                </button>
            </div>
        </div>
    );
};

const HomeownerStep = ({ zip, control, onNext }) => (
    <div>
        <h2 className="text-center text-xl font-bold">Congrats! 👏</h2>
        <p className="text-center font-normal text-lg mb-4"><span className='font-bold'>{zip}</span> is part of the program!</p>

        <h3 className="text-center text-amber-400 text-xl font-bold mb-4">Are you a homeowner?</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <SquareIcon control={control} name="homeowner" value="yes" label="Yes" icon={<FaHome />} onSelect={onNext} />
            <SquareIcon control={control} name="homeowner" value="no" label="No" icon={<FaHome />} onSelect={onNext} />
        </div>
    </div>
);

const RoofAgeStep = ({ control, onNext }) => (
    <div>
        <h2 className="text-center text-xl font-bold mb-4 text-amber-400">Is your roof older than 5 years old?</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <SquareIcon control={control} name="roofAge" value="yes" label="Yes" icon={<FaClock />} onSelect={onNext} />
            <SquareIcon control={control} name="roofAge" value="no" label="No" icon={<FaClock />} onSelect={onNext} />
        </div>
    </div>
);

const RoofTypeStep = ({ control, onNext }) => (
    <div>
        <h2 className="text-xl font-bold mb-4 text-amber-400 text-center">What kind of roof do you currently have?</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <ThumbnailIcon control={control} name="roofType" value="Shingle Roof" label="Shingle Roof" imageUrl="media/asphalt_shingle_roof.webp" onSelect={onNext} />
            <ThumbnailIcon control={control} name="roofType" value="Tile Roof" label="Tile Roof" imageUrl="media/tile_roof.webp" onSelect={onNext} />
            <ThumbnailIcon control={control} name="roofType" value="Metal Roof" label="Metal Roof" imageUrl="media/metal_roof.webp" onSelect={onNext} />
            <ThumbnailIcon control={control} name="roofType" value="Flat Roof" label="Flat Roof" imageUrl="media/flat_roof.webp" onSelect={onNext} />
        </div>
    </div>
);

const Step4 = ({ control, errors }) => (
    <div className="space-y-4">
        <div className="flex flex-col justify-center">
            <p className="text-center text-lg font-semibold text-amber-400">Okay! Seems Like You're Roof Is Eligible For The Program 👍</p>
        </div>

        <h2 className="text-xl font-bold mb-4 text-center">What the address of the project?</h2>
        <Controller
            name="address"
            control={control}
            render={({ field }) => (
                <div>
                    <input
                        {...field}
                        placeholder="Address"
                        autoComplete="address-line1"
                        className={`input text-gray-700 dark:text-yellow-400 w-full ${errors.address ? 'border-red-500' : ''}`}
                    />
                    {errors.address && <p className="text-red-500">{errors.address.message}</p>}
                </div>
            )}
        />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <Controller
                name="city"
                control={control}
                render={({ field }) => (
                    <div>
                        <input
                            {...field}
                            placeholder="City"
                            autoComplete="address-level2"
                            className={`input text-gray-700 dark:text-yellow-400 w-full ${errors.city ? 'border-red-500' : ''}`}
                        />
                        {errors.city && <p className="text-red-500">{errors.city.message}</p>}
                    </div>
                )}
            />
            <Controller
                name="zip"
                control={control}
                render={({ field }) => (
                    <div>
                        <input
                            {...field}
                            placeholder="Zip"
                            autoComplete="postal-code"
                            className={`input text-gray-700 dark:text-yellow-400 w-full ${errors.zip ? 'border-red-500' : ''}`}
                        />
                        {errors.zip && <p className="text-red-500">{errors.zip.message}</p>}
                    </div>
                )}
            />
        </div>
    </div>
);

const ContactInfoStep = ({ control, errors }) => (
    <div className="space-y-4">
        <h2 className="text-xl font-bold text-amber-400 text-center">Excellent, We'll Review All The Details & Give You A Call!</h2>
        <h3 className='text-lg font-semibold text-center'>What's The Best Contact Info To Reach You?</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <Controller
                name="firstName"
                control={control}
                render={({ field }) => (
                    <div>
                        <input
                            {...field}
                            placeholder="First Name"
                            autoComplete="given-name"
                            className={`input text-gray-700 dark:text-yellow-400 w-full ${errors.firstName ? 'border-red-500' : ''}`}
                        />
                        {errors.firstName && <p className="text-red-500">{errors.firstName.message}</p>}
                    </div>
                )}
            />
            <Controller
                name="lastName"
                control={control}
                render={({ field }) => (
                    <div>
                        <input
                            {...field}
                            placeholder="Last Name"
                            autoComplete="family-name"
                            className={`input text-gray-700 dark:text-yellow-400 w-full ${errors.lastName ? 'border-red-500' : ''}`}
                        />
                        {errors.lastName && <p className="text-red-500">{errors.lastName.message}</p>}
                    </div>
                )}
            />
        </div>
        <div className="flex flex-col gap-4">
            <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                    <div className="relative">
                        <div className="flex items-center">
                            <FaPhone className="absolute left-3 text-gray-400" />
                            <input
                                {...field}
                                placeholder="Phone"
                                autoComplete="tel"
                                className={`pl-10 input text-gray-700 dark:text-yellow-400 w-full ${errors.phone ? 'border-red-500' : ''}`}
                            />
                        </div>
                        {errors.phone && <p className="text-red-500 mt-1">{errors.phone.message}</p>}
                    </div>
                )}
            />
            <Controller
                name="email"
                control={control}
                render={({ field }) => (
                    <div className="relative">
                        <div className="flex items-center">
                            <FaEnvelope className="absolute left-3 text-gray-400" />
                            <input
                                {...field}
                                placeholder="Email"
                                autoComplete="email"
                                className={`pl-10 input text-gray-700 dark:text-yellow-400 w-full ${errors.email ? 'border-red-500' : ''}`}
                            />
                        </div>
                        {errors.email && <p className="text-red-500 mt-1">{errors.email.message}</p>}
                    </div>
                )}
            />
        </div>
    </div>
);

const ThankYouStep = ({ formData }) => (
    <div className="">
        <h2 className="text-2xl font-bold mb-4">Thank you, {formData.firstName} {formData.lastName}!</h2>
        <p className="mb-4">We appreciate your interest in our roofing program. Your application has been received and is being processed.</p>
        <p className="mb-4">One of our agents will reviwing your application shortly and will be calling you soon </p>
        <p className="mb-4"><span className="text-yellow-400">Please keep your phone handy, as we will contact you shortly </span> to verify your details and schedule a consultation.</p>
        <p className="mb-4 font-bold">We look forward to helping you get a new roof!</p>
    </div >
);

const SquareIcon = ({ control, name, value, label, icon, onSelect }) => (
    <Controller
        name={name}
        control={control}
        render={({ field }) => (
            <div
                onClick={() => {
                    field.onChange(value);
                    onSelect();
                }}
                className={`cursor-pointer shadow-lg border p-4 rounded-lg flex md:flex-col items-center justify-center md:text-center font-semibold ${field.value === value ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'}`}
            >
                {icon}
                <span className="ml-2">{label}</span>
            </div>
        )}
    />
);

const ThumbnailIcon = ({ control, name, value, label, imageUrl, onSelect }) => (
    <Controller
        name={name}
        control={control}
        render={({ field }) => (
            <div
                onClick={() => {
                    field.onChange(value);
                    onSelect();
                }}
                className={`cursor-pointer border p-4 rounded-lg flex md:flex-col items-center md:justify-center md:text-center font-semibold ${field.value === value ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'}`}
            >
                <img src={imageUrl} alt={label} className="w-16 h-16 object-cover mr-2 rounded" />
                <span className="ml-2">{label}</span>
            </div>
        )}
    />
);

export default RoofingZipCodeMultiStepForm;
